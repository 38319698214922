<template>
    <!-- 전체 콘텐츠 영역 -->    
    <div class="main-contents">        
        <div class="package">
            <div class="table">
                <!-- 기술인재 리스트 -->
                <div class="list-box">
                    <div class="tit flex-box">
                        <span v-if="!$store.state.userInfo.mberSeq">기술인재 찾기</span>
                        <span v-else-if="input.pageId == '1'">기술인재 정보 &gt; 기술인재 찾기</span>
                        <span v-else-if="input.pageId != '1' && this.$store.state.userInfo.mberDivCd == '31'">기술인재 정보 &gt; 소속인재 현황</span>
                        <span v-else>기술인재 정보 &gt; 소속인재 현황</span>
                        <div class="sub_tit" v-if="pageInfo.totalRecordCount !== undefined">{{pageInfo.totalRecordCount}}명의 기술인재가 있습니다!</div>
                    </div>
                    <div class="tech-search-container">
                        <div :class="[searchFlag == true ? 'btn open' : 'btn']" @click="openSearch()">직무분야 찾기</div>
                        <!-- 닫힌 상태에서는 open class를 삭제 해주세요 -->
                        <div :class="[searchFlag == true ? 'container' : 'container displayNone']">
                            <div class="select-area">
                                <ul>
                                <!-- <li><a class="activated" href="">어플리케이션개발</a></li>
                                <li><a class="active" href="">웹프로그램</a></li>
                                <li><a href="">응용프로그램</a></li> -->
                                    <li v-for="(code, index) in searchCodeList" :key="index">
                                        <a :class="[index == searchClick ? 'active' : [code.count > 0 ? 'activated':''] ]" @click="changeClick(index)">{{code.cdNm}}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="result-area" v-if="searchClick == undefined">
                                <div style="width=100%; height:100%; display:flex; justify-content:center; align-items:center;">  
                                    <div><p> &lt; 직무분야를 선택하세요 </p></div>
                                </div>
                            </div>
                            <div class="result-area" v-else>
                                <!-- <li><a href="">전체</a></li>
                                <li><a class="active" href="">Java</a></li>
                                <li><a href="">Python</a></li> -->
                                <ul>
                                    <!-- <li><a @click="allCheck">전체</a></li> -->
                                    <li v-for="(deta, index) in searchCodeList[searchClick].detailList" :key="index">
                                        <a :class="[deta.checked ? 'active' : '']" @click="clickDetail(deta, index)">{{deta.cdNm}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div :class="[searchFlag == true ? 'selected-area' : 'selected-area displayNone']">
                            <ul>
                                <!-- <li>어플리케이션개발 > 백엔드 <button class="btn-delete">삭제</button></li> -->
                                <li v-for="(list, index) in input.searchFilterList" :key="index">{{list.cdNm}}<button class="btn-delete" @click="delDetail(list, index)">삭제</button></li>
                            </ul>
                            <div class="aside">
                                <button class="btn-reset" @click="resetSearchFilter()">초기화</button>
                                <button class="btn-search" @click="getList('1', true)"><span>선택 조건 검색</span></button>
                            </div>
                        </div>
                    </div>
                    <div v-if="!searchFlag" style="margin-bottom : 40px"/>
                    <fix-top :inContent="true" fixClass="top-fix">
                        <template v-slot>
                            <!--div class="search-box">
                                <InputComp type="text" title="기술인재명" v-model="input.searchKeyword" />
                                <div class="search_btn" @click="getList">
                                    <img class="search" src="/images/search_btn.png" alt="검색버튼"/>
                                </div>
                            </div-->
                            

                            <!-- <div class="filter">
                                <div class="filter_box int tech">
                                    <ul>
                                        <li><p class="search_filter">검색 필터</p></li>
                                        <li @click="changeOrderGubun('evalDsc')"><img src="/images/icon_filter.png" alt="평가 높은순 정렬" :class="{filter: input.orderGubun == 'evalDsc'}"/><p>평가등급 높은 순</p><span>ㅣ</span></li>
                                        <li @click="changeOrderGubun('evalAsc')"><img src="/images/icon_filter.png" alt="평가 낮은순 정렬" :class="{filter: input.orderGubun == 'evalAsc'}"/><p>평가등급 낮은 순</p><span>ㅣ</span></li>
                                        <li @click="changeOrderGubun('tecDsc')"><img src="/images/icon_filter.png" alt="기술등급 높은순 정렬" :class="{filter: input.orderGubun == 'tecDsc'}"/><p>기술등급 높은 순</p><span>ㅣ</span></li>
                                        <li @click="changeOrderGubun('tecAsc')"><img src="/images/icon_filter.png" alt="기술등급 낮은순 정렬" :class="{filter: input.orderGubun == 'tecAsc'}"/><p>기술등급 낮은 순</p></li>
                                    </ul>
                                </div>
                            </div> -->
                        </template>
                        <template v-slot:conts>
                            <div class="filter">
                                <div class="filter_box int tech">
                                    <ul>
                                        <li><p class="search_filter">검색 필터</p></li>
                                        <li @click="changeOrderGubun('evalDsc')"><img src="/images/icon_filter.png" alt="평가 높은순 정렬" :class="{filter: input.orderGubun == 'evalDsc'}"/><p>평가등급 높은 순</p><span>ㅣ</span></li>
                                        <li @click="changeOrderGubun('evalAsc')"><img src="/images/icon_filter.png" alt="평가 낮은순 정렬" :class="{filter: input.orderGubun == 'evalAsc'}"/><p>평가등급 낮은 순</p><span>ㅣ</span></li>
                                        <li @click="changeOrderGubun('tecDsc')"><img src="/images/icon_filter.png" alt="기술등급 높은순 정렬" :class="{filter: input.orderGubun == 'tecDsc'}"/><p>기술등급 높은 순</p><span>ㅣ</span></li>
                                        <li @click="changeOrderGubun('tecAsc')"><img src="/images/icon_filter.png" alt="기술등급 낮은순 정렬" :class="{filter: input.orderGubun == 'tecAsc'}"/><p>기술등급 낮은 순</p></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- 기술인재 리스트 -->
                            <div class="Board type3 human tech">
                                <!-- 검색필터 부분-->
                                <div class="check_ft">
                                    <!-- 참여가능여부 -->
                                    <SearchFilter name="참여가능여부" v-model="input.joinPossList" list="Y:참여가능,N:참여불가능" @change="getList('1')"/>
                                    <!-- 기술등급 -->
                                    <SearchFilter name="기술등급" v-model="input.techList" cdId="PRO106" @change="getList('1')"/>
                                    
                                    <!-- NCS 직무등급
                                    <SearchFilter name="NCS 직무등급" v-model="input.ncsList" list="L1,L2,L3,L4,L5,L6,L7,L8,L9" @change="getList()"/>
                                     -->

                                    <!-- 직무 -->
                                    <!-- <SearchFilter name="직무" v-model="input.dutyList" cdId="PRO119" @change="getList()"/> -->

                                    <!-- 경력 -->
                                    <SearchFilter name="경력" v-model="input.careerList" cdId="PRO107" @change="getList('1')"/>

                                    <!-- 학력 -->
                                    <SearchFilter name="학력 - 졸업" v-model="input.schoolList" cdId="PRO121" @change="getList('1')"/>
                                </div>
                                <!-- 기술인재 리스트 -->
                                <template v-if="pageInfo.totalRecordCount !== undefined">
                                    <table class="Board_type3 human tech" v-if="list && list.length > 0">
                                        <colgroup>
                                            <col width="10%"> 
                                            <col width="8%">
                                            <col width="16%">
                                            <col width="8%">
                                            <col width="15%">
                                            <col width="12%">
                                            <col width="*">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>참여가능여부</th>
                                                <th>평가등급</th>
                                                <th>회원명</th>                                                
                                                <th>기술등급</th>
                                                <th>경력</th>
                                                <th>학력-졸업</th>
                                                <th>직무</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in list" :key="item.mberSeq" :class="{click: tecMberSeq == item.mberSeq && tecMberSeq == item.mberSeq}" @click="clickTecMber(item)">
                                                <td class="score">
                                                    <!-- <div class="flag_bl">참여가능</div> -->
                                                    <!-- <div class="flag_bl drk">참여불가능</div> -->
                                                    <div class="flag_bl" v-if="item.joinPossYyyymmdd != 'N'">참여가능</div>
                                                    <div class="flag_bl drk" v-else>참여불가능</div>
                                                </td>
                                                <td class="score">
                                                    <div class="gradeimg">
                                                        <grade-img type="TOT_SM_EVAL" :grade="item.totEvalGradeCd" :specialYn="item.specialGradeYn"/>
                                                    </div>
                                                </td>
                                                <td class="score">{{item.mberNm}} ({{item.gender | gender}} / {{item.birthYear | birthYear}})</td>
                                                <td class="score"><SelectComp type="text" :value="item.resumeTechGradeCd" cdId="PRO106"/></td>
                                                <td class="score">{{item.totWorkMonthCnt | months}}</td>                                                
                                                <!-- <td class="score">학력졸업</td> -->
                                                <td class="score"><SelectComp type="text" :value="item.schoolDivCd" cdId="PRO121"/></td>
                                                <td class="score"><span class="duty"><SelectComp type="text_comma_split" :value="item.dutyCdList" cdId="PRO142"/></span></td>
                                            </tr>
                                            <!--tr class="empty" v-for="index in (maxSize - list.length)" :key="'empty' + index">
                                                <td></td>
                                            </tr-->
                                        </tbody>
                                    </table>
                                    <!-- 기술인재가 없을 경우 -->
                                    <div class="project_box type tech" v-else>
                                        <div class="prj_pf none">
                                            기술인재가 없습니다!
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </fix-top>
                    <!-- 페이징부분 -->
				    <paging-comp :pageInfo="pageInfo" @page-click="goPage"/>

                    <!-- footer -->
                    <!-- <footer-layout type="left"/> -->
                </div>
                <tec-profile :tecMberSeq="tecMberSeq" v-model="detail" @updated="updatedDetail" @profileClose="profileClose()"/>
            </div>
        </div>
    </div>
</template>

<script>
import fixTop from "@/components/FixTop.vue";
import tecProfile from "@/components/highpro/TecProfile.vue";
// import footerLayout from "@/components/layout/footer.vue";
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";

export default {

    components: {
        fixTop, tecProfile, pagingComp, gradeImg
    },
    data() {
        return {
            input: { 
                //searchKeyword : '',
                joinPossList : [],
                techList : [],
                //ncsList : [],
                // dutyList : [],
                careerList: [],
                schoolList : [],
                orderGubun: 'evalDsc',
                pageIndex : '1',
                paramMberSeq : this.$route.params.mberSeq || '',
                searchFilterList : [],
                pageId : this.$route.name || ''
            },
            searchCodeList : [],
            searchClick : undefined,
            searchFlag : false,
			list : [],
			pageInfo: {},
            maxSize: 20,
            tecMberSeq: '',
            detail: { },
        };
    }, 
    mounted(){
        // this.getList();
        if(this.$route.params.mberSeq != ''){
            //console.log('테스트테스트테스트');
            // this.clickTecMber(this.$route.params);
        }

        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu': false})
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },
    beforeMount(){
        if(this.$route.name == 'SRH301M01'){
            this.input.pageId = '1';
        }else{
            this.input.pageId = '2';
        }

        //console.log('파람파람파람', this.$route);
        // clickTecMber(mberSeq){
        //     if(this.$store.state.userInfo.mberSeq){
        //         this.tecMberSeq = mber.mberSeq
        //     } else {
		// 		this.$.popup('/tec/prj/PRJ101P03')
		// 			.then(res => {
		// 				if(res) {
		// 					// 회원가입 화면으로 이동
		// 					this.$router.push({name : 'MEM003M01'});
		// 				}
		// 			});
        //     }
        // }

        var params = this.$route.params

        if(params.cdId){
            if(params.cdId == 'joinPossList') this.input.joinPossList = [params.cd]
            if(params.cdId == 'PRO106') this.input.techList = [params.cd] 
            if(params.cdId == 'PRO121') this.input.schoolList = [params.cd] 

        }

        this.getCodeList();
    },
    methods: {
        getList(div, isScroll = false){

            if(div) this.input.pageIndex = 1;

            this.$.httpPost('/api/mem/srh/searchTecMber', this.input)
                .then(res => {
                    this.list = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}

                    if(this.tecMberSeq){
                        var check = false;
                        for(var i in this.list){
                            if(this.list[i].mberSeq == this.tecMberSeq){
                                check = true;
                                break;
                            }
                        }
                        if(!check) this.tecMberSeq = '';
                    }
                    if(this.$route.params.mberSeq) {
                        this.clickTecMber(this.$route.params);
                        this.$route.params.mberSeq = '';
                        this.input.paramMberSeq = '';
                    }
                }).catch(this.$.httpErrorCommon);
        },
        getCodeList(){
            this.$.httpPost('/api/mem/srh/getSearchCodeList', this.input)
                .then(res => {
                    this.searchCodeList = res.data.codeList;
                    this.getList();
                }).catch(this.$.httpErrorCommon);
        },
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList('', true);
		},
        changeOrderGubun(orderGubun){
            this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getList('', false);
        },
        clickTecMber(item){
            if(this.$store.state.userInfo.mberSeq){
                this.tecMberSeq = item.mberSeq;
            } else {
				this.$.popup('/tec/prj/PRJ101P03')
					.then(res => {
						if(res) {
							// 회원가입 화면으로 이동
							this.$router.push({name : 'MEM003M01'});
						}
					});
            }
        },
        updatedDetail(){
            // console.log('updatedDetail', type, data);
        }, 

        openSearch() {

            if(this.$store.state.userInfo.mberSeq){
                if(!this.searchFlag) {
                    this.searchFlag = true;
                } else {
                    this.searchFlag = false;
                }
            } else {
				this.$.popup('/tec/prj/PRJ101P03')
					.then(res => {
						if(res) {
							// 회원가입 화면으로 이동
							this.$router.push({name : 'MEM003M01'});
						}
					});
            }
        },

        changeClick(idx) {
            this.searchClick = idx;
        },

        allCheck(deta) {
            for(var idx=0; idx < this.input.searchFilterList.length; idx++) {
                if(deta.cd == this.input.searchFilterList[idx].cd.substr(0,2)) {
                    var cd = Number(deta.cd);
                    this.searchCodeList[cd-1].count--;
                    this.searchCodeList[cd-1].detailList[this.input.searchFilterList[idx].detaIndex].checked = false;
                    this.input.searchFilterList.splice(idx,1);
                    idx--;
                }
            }
        },

        clickDetail(deta, idx) {
            if(deta.checked == undefined || deta.checked == false) {
                if(deta.cd.length == 2) {
                    this.allCheck(deta, idx);
                } else if(this.searchCodeList[Number(deta.cd.substr(0,2))-1].detailList[0].checked) {
                    for(var checkIdx in this.input.searchFilterList) {
                        if(deta.cd.substr(0,2) == this.input.searchFilterList[checkIdx].cd) {
                            this.searchCodeList[Number(deta.cd.substr(0,2))-1].count--;
                            this.searchCodeList[Number(deta.cd.substr(0,2))-1].detailList[0].checked = false;
                            this.input.searchFilterList.splice(checkIdx,1);
                        }
                    }
                }

                if(this.input.searchFilterList.length > 14) {
                    alert("직무분야는 최대 '15개' 까지 선택 가능합니다.");
                    return;
                }

                var list = {...deta};
                list.cdNm = this.searchCodeList[this.searchClick].cdNm + " > " + deta.cdNm;
                list.detaIndex = idx;
                deta.checked = true;
                this.input.searchFilterList.push(list);

                if(isNaN(this.searchCodeList[this.searchClick].count) || this.searchCodeList[this.searchClick].count == 0) {
                    this.searchCodeList[this.searchClick].count = 1;
                } else {
                    this.searchCodeList[this.searchClick].count++;
                }

            } else {
                deta.checked = false;
                this.searchCodeList[this.searchClick].count--;
                for(var li = 0; li < this.input.searchFilterList.length; li++) {
                    if(this.input.searchFilterList[li].cd == deta.cd) {
                        this.input.searchFilterList.splice(li,1);
                        break;
                    }
                }
            }   
        },

        delDetail(list, idx) {
            var cd = Number(list.cd.substr(0,2));
            this.searchCodeList[cd-1].count--;
            this.searchCodeList[cd-1].detailList[list.detaIndex].checked = false;
            this.input.searchFilterList.splice(idx,1);
        },

        resetSearchFilter() {
            for(var list of this.input.searchFilterList) {
                var cd = Number(list.cd.substr(0,2));
                this.searchCodeList[cd-1].count--;
                this.searchCodeList[cd-1].detailList[list.detaIndex].checked = false;
            }
            this.input.searchFilterList.splice(0,this.input.searchFilterList.length);
        },

        // 기술인재 상세 닫기
		profileClose() {
			this.tecMberSeq = '';
			this.detail = {};
		},
    },
};
</script>